import Dashboard from './Dashboard';
import Single from './Single';
import Provider from './Provider';
import UnreadBadge from './UnreadBadge';

import './vars.less';

export default {
  Provider,
  Single,
  Dashboard,
  UnreadBadge,
};
