import React from 'react';
import { withRouter } from 'react-router-dom';

import { withSession } from 'stores';

import { request } from 'utils/api';

import Context from './Context';
import MeetingRoom from './MeetingRoom';

@withSession
@withRouter
export default class Provider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docked: false,
      loading: false,
      error: null,
      booking: null,
      participants: [],
    };
  }

  loadParticipants = async (booking) => {
    const { user } = this.context;
    if (!booking) {
      return;
    }
    this.setState({
      error: null,
      loading: true,
    });
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/bookings/${booking.id}/room/participants`,
      });
      this.setState({
        booking,
        loading: false,
        participants: data.filter((participant) => {
          const userId = participant.identity.split(' | ')[0];
          return userId !== user.id;
        }),
      });
    } catch (error) {
      this.setState({
        error,
        loading: true,
      });
    }
  };

  onJoinClick = async () => {
    const { booking } = this.state;
    if (!booking) {
      return;
    }
    this.setState({
      error: null,
      loading: true,
    });
    try {
      const { data } = await request({
        method: 'POST',
        path: `/1/bookings/${booking.id}/room/generate-token`,
      });
      this.setState({
        token: data,
        joined: true,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: true,
      });
    }
  };

  onPopoutClick = () => {
    this.setState({
      docked: true,
    });
  };

  setBooking = (booking) => {
    if (booking.id === this.state.booking?.id) {
      return;
    }
    this.loadParticipants(booking);
  };

  setDocked = (docked) => {
    if (this.state.joined) {
      this.setState({
        docked,
      });
    }
  };

  onDisconnect = () => {
    this.setState({
      joined: false,
      docked: false,
    });
  };

  onPopinClick = () => {
    const { booking } = this.state;
    const clientId = booking.client.id || booking.client;
    const bookingId = booking.id;
    const path = `/clients/${clientId}/appointments/${bookingId}`;
    this.props.history.push(path);
    this.setDocked(false);
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          setDocked: this.setDocked,
          setBooking: this.setBooking,
          onJoinClick: this.onJoinClick,
          onDisconnect: this.onDisconnect,
          onPopoutClick: this.onPopoutClick,
          onPopinClick: this.onPopinClick,
        }}>
        {this.props.children}
        {this.renderDocked()}
      </Context.Provider>
    );
  }

  renderDocked() {
    if (this.state.docked) {
      return <MeetingRoom />;
    }
  }
}
