import React from 'react';
import { Form, Input, Button, Loader } from 'semantic';
import { debounce, groupBy } from 'lodash';
import { Link } from 'react-router-dom';

import bem from 'helpers/bem';

import { request } from 'utils/api';
import { captureError } from 'utils/sentry';
import { DateTime, formatTime } from 'utils/date';
import { isProvider, isManager } from 'utils/user';

import { getPublicParticipants } from './utils';
import Avatar from './Avatar';

import client, { GroupChannelHandler } from './client';
import './channel.less';

import Context from './Context';

const ONE_MINUTE = 60 * 1000;

@bem
export default class SendbirdChannel extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      messages: [],
      newMessage: {},
    };
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    this.setup();
    this.checkReadHorizon();
  }

  componentDidUpdate(lastProps) {
    const { channel } = this.props;
    const { channel: lastChannel } = lastProps;
    if (channel !== lastChannel) {
      this.destroy();
      this.setup();
    }
  }

  componentWillUnmount() {
    this.destroy();
  }

  getModifiers() {
    const { single } = this.props;
    return [single ? 'single' : null];
  }

  onMessageReceived = (channel, message) => {
    this.setState({
      messages: [...this.state.messages, message],
    });
    this.scrollToBottom();
  };

  onNewMessageSucceeded = (message) => {
    this.setState({
      messages: [...this.state.messages, message],
    });
    if (this.isProviderFirstResponse(message)) {
      this.updateAverageResponseTime(message);
    }
    this.scrollToBottom();
  };

  isProviderFirstResponse = (newMessage) => {
    if (!isProvider(this.context.user)) {
      return false;
    }
    const hasPrevMessages = this.state.messages.some((message) => {
      return this.isSelf(message) && message.createdAt < newMessage.createdAt;
    });
    return !hasPrevMessages;
  };

  updateAverageResponseTime = async (newMessage) => {
    const firstClientMessage = this.state.messages.find((message) => {
      return !this.isSelf(message);
    });
    if (!firstClientMessage) {
      return;
    }
    const dt = newMessage.createdAt - firstClientMessage.createdAt;
    const minutes = Math.round(dt / ONE_MINUTE);
    try {
      await request({
        method: 'POST',
        path: '/1/providers/response-time',
        body: {
          minutes,
        },
      });
    } catch (error) {
      captureError(error);
    }
  };

  // Typing

  onTypingStatusUpdated = () => {
    this.forceUpdate();
  };

  canRenderTyping(message) {
    const { channel } = this.props;
    const typingUsers = channel.getTypingUsers();

    const isTyping = typingUsers.some((m) => {
      return m.userId === message.sender.userId;
    });

    return isTyping && this.isLastMessage(message);
  }

  isLastMessage(message) {
    const { messages } = this.state;
    const lastMessage = messages.findLast((m) => {
      return m.sender.userId === message.sender.userId;
    });
    return message.messageId === lastMessage?.messageId;
  }

  async setup() {
    this.setState({
      loading: true,
    });
    const { channel } = this.props;
    const handler = new GroupChannelHandler({
      onMessageReceived: this.onMessageReceived,
      onMessageUpdated: this.onMessageUpdated,
      onTypingStatusUpdated: this.onTypingStatusUpdated,
    });

    client.groupChannel.addGroupChannelHandler('channel-handler', handler);

    const messages = await channel.getMessagesByTimestamp(Date.now(), {
      prevResultSize: 200,
      nextResultSize: 10,
    });

    this.setState(
      {
        messages,
        loading: false,
      },
      () => {
        this.scrollToBottom();
      }
    );
  }

  destroy() {
    client.groupChannel.removeGroupChannelHandler('channel-handler');
    this.setState({
      messages: [],
    });
  }

  onMessageBodyChange = (evt, { value }) => {
    this.setState({
      newMessage: {
        ...this.state.newMessage,
        body: value,
      },
    });
    this.props.channel.startTyping();
  };

  onNewMessageSubmit = () => {
    const { newMessage } = this.state;

    this.props.channel
      .sendUserMessage({
        message: newMessage.body,
      })
      .onSucceeded(this.onNewMessageSucceeded);

    this.setState({
      newMessage: {},
    });

    this.props.channel.endTyping();
  };

  isSelf(message) {
    const { user } = this.context;
    return message.sender.userId === user.id;
  }

  onScroll = () => {
    this.checkReadHorizon();
  };

  // Simple for now just setting all as read
  // if scroll bottom is reached
  checkReadHorizon = debounce(async () => {
    const el = this.scrollRef.current;
    // Check is we're at the bottom with a small margin;
    const isAtBottom = el.clientHeight + el.scrollTop > el.scrollHeight - 5;
    if (isAtBottom) {
      this.props.channel.markAsRead();
    }
  }, 300);

  scrollToBottom = () => {
    const el = this.scrollRef.current;
    el.scrollTop = el.scrollHeight;
  };

  isManager() {
    return isManager(this.context.user);
  }

  render() {
    return (
      <div className={this.getBlockClass()}>
        {this.renderLoading()}
        {this.renderHeader()}
        {this.renderGroupedMessages()}
        {this.renderNewMessage()}
      </div>
    );
  }

  renderLoading() {
    if (this.state.loading) {
      return <Loader active />;
    }
  }

  renderHeader() {
    const { channel } = this.props;
    const [user1, user2] = getPublicParticipants(channel);
    const isPriority = this.context.isPriorityChannel(channel);
    return (
      <div className={this.getElementClass('header')}>
        <div className={this.getElementClass('header-users')}>
          {user1 && (
            <div className={this.getElementClass('header-user')}>
              <Link to={`/clients/${user1.userId}`}>
                <Avatar src={user1.profileUrl} small />
                {user1.nickname}
              </Link>
            </div>
          )}
          {user2 && (
            <React.Fragment>
              <div className={this.getElementClass('header-bar')} />
              <div className={this.getElementClass('header-user')}>
                <Avatar src={user2.profileUrl} small />
                {user2.nickname}
              </div>
            </React.Fragment>
          )}
        </div>
        <div
          className={this.getElementClass('header-action')}
          onClick={() => {
            this.context.togglePriorityChannel(channel);
          }}>
          <div
            className={this.getElementClass(
              'header-action-icon',
              isPriority ? 'priority' : null
            )}>
            !
          </div>
          <span>{isPriority ? 'Unmark as priority' : 'Mark as priority'}</span>
        </div>
      </div>
    );
  }

  renderGroupedMessages() {
    const { messages } = this.state;
    const grouped = groupBy(messages, (message) => {
      const dt = new DateTime(message.createdAt);
      return dt.formatDate();
    });

    return (
      <div
        ref={this.scrollRef}
        className={this.getElementClass('messages')}
        onScroll={this.onScroll}>
        {Object.entries(grouped).map(([date, messages]) => {
          return (
            <React.Fragment key={date}>
              <div className={this.getElementClass('date')}>{date}</div>
              {this.renderMessages(messages)}
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  renderMessages(messages) {
    const { user } = this.context;
    return messages.map((message) => {
      const isSelf = message.sender.userId === user.id;

      const typing = this.canRenderTyping(message);

      const delivered = message.sendingStatus === 'succeeded';
      const { messageId: id, message: body, createdAt } = message;
      const date = new Date(createdAt);
      return (
        <div
          key={id}
          className={this.getElementClass(
            'message',
            isSelf ? 'self' : 'participant'
          )}>
          <Avatar src={message.sender.profileUrl} />
          <div
            className={this.getElementClass(
              'message-content',
              isSelf ? 'self' : 'participant'
            )}>
            <div className={this.getElementClass('message-body')}>{body}</div>
            <div className={this.getElementClass('message-meta')}>
              {typing ? (
                'Typing...'
              ) : (
                <React.Fragment>
                  <span>{formatTime(date)}</span>
                  {isSelf && delivered && <span>Delivered</span>}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      );
    });
  }

  renderTyping() {
    const { channel } = this.props;
    const typingUsers = channel.getTypingUsers();
    if (typingUsers.length) {
      return (
        <div className={this.getElementClass('typing-indicator')}>
          <span className={this.getElementClass('typing-indicator-dot')} />
          <span className={this.getElementClass('typing-indicator-dot')} />
          <span className={this.getElementClass('typing-indicator-dot')} />
        </div>
      );
    }
  }

  renderNewMessage() {
    if (this.isManager()) {
      return;
    }
    const { loading, newMessage } = this.state;
    if (loading) {
      return;
    }
    return (
      <div className={this.getElementClass('new-message')}>
        <Form onSubmit={this.onNewMessageSubmit}>
          <Input
            value={newMessage.body || ''}
            onChange={this.onMessageBodyChange}
          />
          <Button icon="paper-plane" />
        </Form>
      </div>
    );
  }
}
