import React from 'react';
import { Dropdown } from 'semantic';

export default class TimeDropdown extends React.Component {
  getValue() {
    let { value } = this.props;
    if (this.isDate()) {
      value = [
        value.getHours(),
        value.getMinutes().toString().padStart(2, '0'),
      ].join(':');
    }
    return value;
  }

  getOptions() {
    const options = [];
    const min = this.getMin();
    const max = this.getMax();
    for (let hours = 0; hours < 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const time = this.getTime(hours, minutes);
        if (time < min || time > max) {
          continue;
        }
        const mm = minutes.toString().padStart(2, '0');
        const value = `${hours}:${mm}`;

        let hh = hours;
        if (hh > 12) {
          hh %= 12;
        }

        const text = `${hh}:${mm}${hours >= 12 ? 'pm' : 'am'}`;

        options.push({
          value,
          text,
        });
      }
    }
    return options;
  }

  isDate() {
    return this.props.value instanceof Date;
  }

  onChange = (evt, props) => {
    if (this.isDate()) {
      const [hours, minutes] = props.value.split(':');
      const date = new Date(this.props.value);
      date.setHours(hours);
      date.setMinutes(minutes);
      this.props.onChange(evt, {
        ...props,
        value: date,
      });
    } else {
      this.props.onChange(evt, props);
    }
  };

  getMin() {
    const { min = '00:00' } = this.props;
    return this.parseMinutes(min);
  }

  getMax() {
    const { max = '23:59' } = this.props;
    return this.parseMinutes(max);
  }

  getTime(hours, minutes) {
    return (hours || 0) * 60 + (minutes || 0);
  }

  parseMinutes(str) {
    const [hours, minutes] = str.split(':');
    return this.getTime(+hours, +minutes);
  }

  render() {
    return (
      <Dropdown
        selection
        {...this.props}
        value={this.getValue()}
        options={this.getOptions()}
        onChange={this.onChange}
      />
    );
  }
}
