import React from 'react';

import bem from 'helpers/bem';

import Context from './Context';

import './unread-badge.less';

@bem
export default class SendbirdUnreadBadge extends React.Component {
  static contextType = Context;

  render() {
    const count = this.context.getUnreadMessageCount();
    if (count === 0) {
      return null;
    }
    return <div className={this.getBlockClass()}>{count}</div>;
  }
}
