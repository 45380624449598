// TODO: this entire file can now be replaced in favor
// of internal formatting methods of DateTime.js

const DATE_MED = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

const TIME_MED = {
  hour: 'numeric',
  minute: 'numeric',
};

const DATETIME_MED = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

const MONTH_YEAR = {
  year: 'numeric',
  month: 'long',
};

const HOURS = {
  hour: 'numeric',
};

export function formatDate(arg, options) {
  return getFormatted(arg, {
    ...options,
    ...DATE_MED,
  });
}

export function formatTime(arg, options) {
  return getFormatted(arg, {
    ...options,
    ...TIME_MED,
  });
}

export function formatDateTime(arg, options) {
  return getFormatted(arg, {
    ...options,
    ...DATETIME_MED,
  });
}

export function formatMonthYear(arg, options) {
  return getFormatted(arg, {
    ...options,
    ...MONTH_YEAR,
  });
}

export function formatHours(arg, options) {
  return getFormatted(arg, {
    ...options,
    ...HOURS,
  });
}

export function formatDob(arg, options) {
  if (typeof arg === 'string') {
    arg = `${arg.split('T')[0]}T12:00:00.000Z`;
  }
  return formatDate(arg, options);
}

function getFormatted(arg, options) {
  if (!arg) {
    return '-';
  }
  const { locale, ...rest } = options;
  const { timeZone = 'America/New_York' } = window;
  const date = typeof arg === 'string' ? new Date(arg) : arg;
  let str = new Intl.DateTimeFormat(locale, {
    ...rest,
    timeZone,
  }).format(date);
  str = str.replace(/ (AM|PM)/, (str, t) => {
    return t === 'AM' ? 'am' : 'pm';
  });
  return str;
}

export function fromNow(
  date,
  nowDate = Date.now(),
  rft = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' })
) {
  const SECOND = 1000;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const WEEK = 7 * DAY;
  const MONTH = 30 * DAY;
  const YEAR = 365 * DAY;

  const intervals = [
    { ge: YEAR, divisor: YEAR, unit: 'year' },
    { ge: MONTH, divisor: MONTH, unit: 'month' },
    { ge: WEEK, divisor: WEEK, unit: 'week' },
    { ge: DAY, divisor: DAY, unit: 'day' },
    { ge: HOUR, divisor: HOUR, unit: 'hour' },
    { ge: MINUTE, divisor: MINUTE, unit: 'minute' },
    { ge: 30 * SECOND, divisor: SECOND, unit: 'seconds' },
    { ge: 0, divisor: 1, text: 'just now' },
  ];

  const now =
    typeof nowDate === 'object'
      ? nowDate.getTime()
      : new Date(nowDate).getTime();

  const diff =
    now - (typeof date === 'object' ? date : new Date(date)).getTime();

  const diffAbs = Math.abs(diff);

  for (const interval of intervals) {
    if (diffAbs >= interval.ge) {
      const x = Math.round(Math.abs(diff) / interval.divisor);
      const isFuture = diff < 0;
      return interval.unit
        ? rft.format(isFuture ? x : -x, interval.unit)
        : interval.text;
    }
  }
}
