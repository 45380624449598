import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { createLocalTracks, connect as twilioConnect } from 'twilio-video';
import { Icon } from 'semantic';

import bem from 'helpers/bem';

import './twilio-video.less';

// Maintain global state of media tracks to sync
// their state when video becomes docked.
let audioEnabled = true;
let videoEnabled = true;

@bem
export default class TwilioVideo extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.localRef = React.createRef();
    this.state = {
      meetingStarted: false,
    };
  }

  componentDidMount() {
    this.connect();
    this.setupFullscreen();
    window.addEventListener('beforeunload', this.onWindowUnload);
  }

  componentWillUnmount() {
    this.destroyFullscreen();
    window.removeEventListener('beforeunload', this.onWindowUnload);
  }

  // Events

  onWindowUnload = (evt) => {
    if (this.room) {
      evt.preventDefault();
      // Note: most modern browsers won't respect this
      evt.returnValue = 'Do you really want to reload?';
    }
  };

  // Twilio

  connect = async () => {
    const { token } = this.props;
    const localTracks = await createLocalTracks({
      audio: true,
      video: true,
    });
    let room;
    try {
      room = await twilioConnect(token, {
        tracks: localTracks,
      });
    } catch (error) {
      for (let track of localTracks) {
        track.stop();
      }
      throw error;
    }

    room.on('participantConnected', (participant) => {
      this.addParticipant(participant);
      this.checkConnectedParticipants();
      this.forceUpdate();
    });

    room.on('participantDisconnected', () => {
      this.checkConnectedParticipants();
      this.forceUpdate();
    });

    room.on('disconnected', (room) => {
      // Detach the local media elements
      room.localParticipant.tracks.forEach((publication) => {
        publication.unpublish();
        publication.track.stop();
      });
    });

    localTracks.forEach((track) => {
      this.localRef.current.appendChild(track.attach());
    });

    room.participants.forEach((participant) => {
      this.addParticipant(participant);
    });

    this.room = room;

    if (!audioEnabled) {
      this.toggleMicrophone();
    }
    if (!videoEnabled) {
      this.toggleCamera();
    }

    this.checkConnectedParticipants();
    this.forceUpdate();
  };

  disconnect = () => {
    if (this.room) {
      this.room.disconnect();
      this.checkConnectedParticipants();
      this.props.onDisconnect();
    }
  };

  addParticipant(participant) {
    const { identity } = participant;

    participant.on('trackSubscribed', (track) => {
      const el = document.querySelector(`[data-identity="${identity}"]`);
      el.appendChild(track.attach());

      track.on('enabled', () => {
        this.forceUpdate();
      });

      track.on('disabled', () => {
        this.forceUpdate();
      });
    });

    participant.on('trackUnsubscribed', (track) => {
      const elements = track.detach();
      elements.forEach((el) => {
        el.remove();
      });
    });
  }

  toggleMicrophone = () => {
    const isEnabled = this.isAudioEnabled(this.room.localParticipant);
    this.room.localParticipant.audioTracks.forEach((publication) => {
      if (isEnabled) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });
    audioEnabled = !isEnabled;
    this.forceUpdate();
  };

  toggleCamera = () => {
    const isEnabled = this.isVideoEnabled(this.room.localParticipant);
    this.room.localParticipant.videoTracks.forEach((publication) => {
      if (isEnabled) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });
    videoEnabled = !isEnabled;
    this.forceUpdate();
  };

  // Fullscreen

  setupFullscreen() {
    document.addEventListener('fullscreenchange', this.onFullscreenChange);
  }

  destroyFullscreen() {
    document.removeEventListener('fullscreenchange', this.onFullscreenChange);
  }

  onFullscreenChange = () => {
    this.forceUpdate();
  };

  toggleFullscreen = () => {
    if (this.isFullscreen()) {
      document.exitFullscreen();
    } else {
      this.ref.current.requestFullscreen();
    }
  };

  // Check time on call

  checkConnectedParticipants() {
    const hasParticipants = this.room.participants.size > 0;
    const isConnected = this.room.localParticipant.state === 'connected';
    const meetingStarted = hasParticipants && isConnected;

    if (meetingStarted && !this.state.meetingStarted) {
      this.onMeetingStarted();
    } else if (!meetingStarted && this.state.meetingStarted) {
      this.onMeetingEnded();
    }

    this.setState({
      meetingStarted,
    });
  }

  onMeetingStarted() {
    this.setState({
      startedAt: new Date(),
    });
  }

  onMeetingEnded() {
    const { startedAt } = this.state;
    if (startedAt) {
      this.props.onMeetingEnded?.(new Date() - startedAt);
      this.setState({
        startedAt: null,
      });
    }
  }

  // Utility

  getParticipantName(participant) {
    const { identity } = participant;
    return identity.split(' | ')[1];
  }

  isAudioEnabled(participant) {
    return Array.from(participant.audioTracks.values()).some((publication) => {
      return publication.isTrackEnabled;
    });
  }

  isVideoEnabled(participant) {
    return Array.from(participant.videoTracks.values()).some((publication) => {
      return publication.isTrackEnabled;
    });
  }

  isFullscreen() {
    return document.fullscreenElement === this.ref.current;
  }

  getParticipants() {
    if (!this.room) {
      return 0;
    }
    return this.room.participants.size;
  }

  hasParticipants() {
    return this.getParticipants() > 0;
  }

  getModifiers() {
    return [this.hasParticipants() ? 'active' : null];
  }

  render() {
    return (
      <div ref={this.ref} className={this.getBlockClass()}>
        {this.renderRemotes()}
        {this.renderLocal()}
        {this.renderControls()}
      </div>
    );
  }

  renderRemotes() {
    if (!this.room) {
      return null;
    }
    return (
      <div className={this.getElementClass('remotes')}>
        {Array.from(this.room.participants.values()).map((participant) => {
          const name = this.getParticipantName(participant);
          const muted = !this.isAudioEnabled(participant);
          return (
            <div
              key={participant.identity}
              className={this.getElementClass('remote')}>
              <div
                className={this.getElementClass('remote-media')}
                data-identity={participant.identity}
              />
              <div className={this.getElementClass('remote-name')}>
                {name}
                {muted && (
                  <div className={this.getElementClass('remote-muted')}>
                    <Icon name="microphone-slash" />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderLocal() {
    return (
      <div className={this.getElementClass('local')} ref={this.localRef} />
    );
  }

  renderControls() {
    if (!this.room) {
      return;
    }
    return (
      <div className={this.getElementClass('controls')}>
        {this.renderBackButton()}
        {this.renderMicrophone()}
        {this.renderCamera()}
        {this.renderFullscreen()}
        {this.renderDisconnect()}
      </div>
    );
  }

  renderMicrophone() {
    const isEnabled = this.isAudioEnabled(this.room.localParticipant);
    return (
      <div
        onClick={this.toggleMicrophone}
        className={this.getElementClass(
          'control',
          isEnabled ? null : 'disabled'
        )}>
        <Icon name={isEnabled ? 'microphone' : 'microphone-slash'} />
      </div>
    );
  }

  renderCamera() {
    const isEnabled = this.isVideoEnabled(this.room.localParticipant);
    return (
      <div
        onClick={this.toggleCamera}
        className={this.getElementClass(
          'control',
          isEnabled ? null : 'disabled'
        )}>
        <Icon name={isEnabled ? 'video' : 'video-slash'} />
      </div>
    );
  }

  renderFullscreen() {
    const isFullscreen = this.isFullscreen();
    return (
      <div
        onClick={this.toggleFullscreen}
        className={this.getElementClass(
          'control',
          isFullscreen ? 'disabled' : null
        )}>
        <Icon name={isFullscreen ? 'compress' : 'expand'} />
      </div>
    );
  }

  renderBackButton() {
    if (this.props.onPopinClick) {
      return (
        <div
          onClick={this.props.onPopinClick}
          className={this.getElementClass('control')}>
          <Icon name="arrow-left" />
        </div>
      );
    }
  }

  renderDisconnect() {
    if (!this.room) {
      return;
    }
    return (
      <div
        onClick={this.disconnect}
        className={this.getElementClass('control', 'disconnect')}>
        <Icon name="phone" />
      </div>
    );
  }
}

TwilioVideo.propTypes = {
  token: PropTypes.string.isRequired,
  onDisconnect: PropTypes.func.isRequired,
  onMeetingEnded: PropTypes.func,
};

TwilioVideo.defaultProps = {
  onMeetingEnded: noop,
};
