import React from 'react';

import Context from './Context';
import MeetingRoom from './MeetingRoom';

export default class Outlet extends React.Component {
  static contextType = Context;

  componentDidMount() {
    const { booking } = this.props;
    this.context.setBooking(booking);
  }

  componentWillUnmount() {
    this.context.setDocked(true);
  }

  render() {
    const { docked } = this.context;
    if (docked) {
      return null;
    } else {
      return <MeetingRoom />;
    }
  }
}
