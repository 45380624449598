import React from 'react';

import bem from 'helpers/bem';

import avatar from 'assets/avatar.svg';

import './avatar.less';

@bem
export default class SendbirdAvatar extends React.Component {
  getModifiers() {
    const { src, small } = this.props;
    return [src ? null : 'blank', small ? 'small' : null];
  }
  render() {
    const { src, small, ...rest } = this.props;

    return (
      <img {...rest} src={src || avatar} className={this.getBlockClass()} />
    );
  }
}
