export function isManagerMember(member) {
  return member.metaData?.role === 'manager';
}

export function isProviderMember(member) {
  return member.metaData?.role === 'provider';
}

export function getPublicParticipants(channel) {
  return channel.members
    .filter((member) => {
      return !isManagerMember(member);
    })
    .sort((a, b) => {
      const aProvider = isProviderMember(a);
      const bProvider = isProviderMember(b);
      if (aProvider !== bProvider) {
        return aProvider ? 1 : -1;
      } else {
        return 0;
      }
    });
}
