import React from 'react';
import { omit, sumBy } from 'lodash';

import { withSession, useSession } from 'stores';

import bem from 'helpers/bem';

import { request } from 'utils/api';
import { isManager, isProvider } from 'utils/user';

import Context from './Context';
import client, { GroupChannelHandler } from './client';

// Wrapper prevents persistent connections
// from lingering on logout.
export default function Wrapper(props) {
  const { user } = useSession();
  return <SendbirdChatProvider key={user?.id} {...props} />;
}

@bem
@withSession
class SendbirdChatProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      channels: [],
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentWillUnmount() {
    this.destroy();
  }

  onChannelChanged = () => {
    this.forceUpdate();
  };

  onUnreadMemberStatusUpdated = () => {
    this.forceUpdate();
  };

  getUnreadMessageCount = () => {
    const { channels } = this.state;
    return sumBy(channels, (channel) => {
      return channel.unreadMessageCount;
    });
  };

  isPriorityChannel = (channel) => {
    return this.getPriorityChannels()[channel.url] === true;
  };

  togglePriorityChannel = (channel) => {
    if (this.isPriorityChannel(channel)) {
      const priority = omit(this.getPriorityChannels(), [channel.url]);
      this.setPriorityChannels(priority);
    } else {
      this.setPriorityChannels({
        ...this.getPriorityChannels(),
        [channel.url]: true,
      });
    }
  };

  getPriorityChannels() {
    return this.context.stored['priorityChannels'] || {};
  }

  setPriorityChannels(channels) {
    return this.context.setStored('priorityChannels', channels);
  }
  async setup() {
    await this.setupConnection();
    await this.setupChannels();
    this.setState({
      ready: true,
    });
  }

  destroy() {
    client.groupChannel.removeGroupChannelHandler('conversations-handler');
  }

  // Connection

  async setupConnection() {
    const { user: self } = this.context;
    try {
      if (isManager(self)) {
        await this.setupManager(self);
      } else if (isProvider(self)) {
        await this.setupProvider(self);
      } else if (self) {
        await this.setupClient(self);
      }
    } catch (error) {
      this.setState({
        error,
      });
    }
  }

  async setupManager() {
    const { data } = await request({
      method: 'GET',
      path: '/1/chat/manager',
    });
    await client.connect(data.id, data.token);
  }

  async setupProvider(self) {
    const { data } = await request({
      method: 'POST',
      path: '/1/chat/generate-token',
    });
    await client.connect(self.id, data.token);
  }

  async setupClient(self) {
    const { data } = await request({
      method: 'POST',
      path: '/1/chat/generate-token',
    });
    await client.connect(self.id, data.token);
  }

  async connectUser(user) {
    const { data } = await request({
      method: 'POST',
      path: '/1/chat/connect',
      body: {
        user: user.id,
      },
    });
    return await client.groupChannel.getChannel(data.channel_url);
  }

  // Channels

  async setupChannels() {
    const { user } = this.context;
    if (!user) {
      return;
    }
    const query = client.groupChannel.createMyGroupChannelListQuery();
    const channels = await query.next();

    const handler = new GroupChannelHandler({
      onChannelChanged: this.onChannelChanged,
      onUnreadMemberStatusUpdated: this.onUnreadMemberStatusUpdated,
    });

    client.groupChannel.addGroupChannelHandler(
      'conversations-handler',
      handler
    );

    this.setState({
      channels,
    });
  }

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          user: this.context.user,
          isPriorityChannel: this.isPriorityChannel,
          togglePriorityChannel: this.togglePriorityChannel,
          getUnreadMessageCount: this.getUnreadMessageCount,
        }}>
        {this.props.children}
      </Context.Provider>
    );
  }
}
