import SendbirdChatModule from '@sendbird/chat';

import {
  GroupChannelModule,
  GroupChannelHandler,
} from '@sendbird/chat/groupChannel';

import { SENDBIRD_APPLICATION_ID } from 'utils/env';

export default SendbirdChatModule.init({
  appId: SENDBIRD_APPLICATION_ID,
  modules: [new GroupChannelModule()],
});

export { GroupChannelHandler };
