import React from 'react';
import { debounce } from 'lodash';
import { Button, Dimmer, Loader } from 'semantic';

import bem from 'helpers/bem';

import TwilioVideo from 'components/TwilioVideo';

import Context from './Context';

import './meeting-room.less';

@bem
export default class MeetingRoom extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      locked: false,
      scrolled: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    if (this.state.locked) {
      return;
    }
    const { scrollY } = window;
    const { offsetTop } = this.ref.current;
    const dTop = offsetTop - scrollY;
    const scrolled = dTop <= 0;
    if (scrolled !== this.state.scrolled) {
      this.setState({
        scrolled,
      });
      if (scrolled) {
        window.scrollTo(0, offsetTop);
        this.scrollLock();
      }
    }
  };

  scrollLock = () => {
    this.setState({
      locked: true,
    });
    this.scrollUnlock();
  };

  scrollUnlock = debounce(() => {
    this.setState({
      locked: false,
    });
  }, 300);

  getModifiers() {
    const { docked } = this.context;
    if (this.canStick()) {
      return ['sticking'];
    } else if (docked) {
      return ['docked'];
    }
  }

  canStick() {
    const { joined, docked } = this.context;
    const { scrolled } = this.state;
    return joined && !docked && scrolled;
  }

  render() {
    return (
      <div ref={this.ref} className={this.getBlockClass()}>
        {this.renderForState()}
      </div>
    );
  }

  renderForState() {
    const { loading, joined, token, docked } = this.context;
    if (loading) {
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );
    } else if (joined) {
      return (
        <TwilioVideo
          token={token}
          onPopinClick={docked ? this.context.onPopinClick : null}
          onDisconnect={this.context.onDisconnect}
          onMeetingEnded={this.context.onMeetingEnded}
          onMeetingStarted={this.context.onMeetingStarted}
        />
      );
    } else {
      return this.renderLobby();
    }
  }

  renderLobby() {
    return (
      <div className={this.getElementClass('lobby')}>
        <div className={this.getElementClass('participants')}>
          {this.renderParticipants()}
        </div>
        <Button onClick={this.context.onJoinClick}>Join Meeting</Button>
      </div>
    );
  }

  renderParticipants() {
    const { participants } = this.context;
    if (participants.length === 0) {
      return 'No one seems to be here.';
    } else if (participants.length === 1) {
      const [participant] = participants;
      return `${participant.identity.split(' | ')[1]} is in the meeting.`;
    } else if (participants.length > 1) {
      return 'Multiple participants are in the meeting.';
    }
  }
}
