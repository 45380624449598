import React from 'react';

import bem from 'helpers/bem';

import ErrorMessage from 'components/ErrorMessage';

import Channel from './Channel';
import Conversations from './Conversations';

import Context from './Context';

import './dashboard.less';

@bem
export default class SendbirdChatDashboard extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  onChannelSelect = (channel) => {
    if (channel.url !== this.state.selected?.url) {
      this.setState({
        selected: channel,
      });
    }
  };

  render() {
    const { error } = this.context;
    return (
      <div className={this.getBlockClass()}>
        <ErrorMessage error={error} />
        {this.renderConversations()}
        {this.renderChannel()}
      </div>
    );
  }

  renderConversations() {
    const { selected } = this.state;
    return (
      <Conversations
        currentChannel={selected}
        onChannelSelect={this.onChannelSelect}
      />
    );
  }

  renderChannel() {
    const { selected } = this.state;
    if (selected) {
      return <Channel channel={selected} />;
    }
  }
}
