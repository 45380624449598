// Displays an error message if an error is passed
// also captures and logs error stack for production
// debugging.

import React from 'react';
import { Message, Icon } from 'semantic';

import bem from 'helpers/bem';

import { ApiError } from 'utils/api';
import { CustomError } from 'utils/error';
import { captureError } from 'utils/sentry';

import './error-message.less';

@bem
export default class ErrorMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: props.rollup ? false : true,
    };
  }

  onDetailsClick = () => {
    this.setState({
      showDetails: true,
    });
  };

  componentDidUpdate(lastProps) {
    const { error } = this.props;
    if (error !== lastProps.error) {
      if (this.canLogError(error)) {
        // eslint-disable-next-line
        console?.error(error);
        captureError(error);
      }
      this.setState({
        open: false,
      });
    }
  }

  canLogError(error) {
    if (error instanceof ApiError) {
      return error.status >= 500;
    } else if (error instanceof CustomError) {
      return false;
    } else {
      return !!error;
    }
  }

  render() {
    const { error } = this.props;
    if (!error) {
      return null;
    }
    return <Message error>{this.renderDetails(error)}</Message>;
  }

  renderDetails(error) {
    const { rollup } = this.props;
    return (
      <React.Fragment>
        <p>{error.message}</p>
        {rollup && (
          <p
            onClick={this.onDetailsClick}
            className={this.getElementClass('show-details')}>
            Show Details <Icon name="caret-right" />
          </p>
        )}
      </React.Fragment>
    );
  }
}
