import React from 'react';

import bem from 'helpers/bem';

import Context from './Context';

@bem
export default class MeetingRoomSpacer extends React.Component {
  static contextType = Context;

  render() {
    const { docked } = this.context;
    if (docked) {
      return <div className={this.getBlockClass()} />;
    } else {
      return null;
    }
  }
}
