import React from 'react';
import { Loader, Dropdown, Radio, Icon } from 'semantic';

import bem from 'helpers/bem';

import { Layout } from 'components';

import { isManager } from 'utils/user';

import { getPublicParticipants } from './utils';
import Avatar from './Avatar';
import Context from './Context';

import './conversations.less';

@bem
export default class SendbirdConversations extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      sort: 'newest',
    };
  }

  getModifiers() {
    const { channels } = this.context;
    return [!channels.length ? 'empty' : null];
  }

  onSortClick = (evt, { value }) => {
    this.setState({
      sort: value,
    });
  };

  sortChannels = (a, b, sort) => {
    if (sort === 'newest') {
      return b.joinedAt - a.joinedAt;
    } else if (sort === 'oldest') {
      return a.joinedAt - b.joinedAt;
    } else if (sort === 'priority') {
      return (
        this.context.isPriorityChannel(b) - this.context.isPriorityChannel(a)
      );
    }
  };

  render() {
    return (
      <div className={this.getBlockClass()}>
        {this.renderLoading()}
        {this.renderHeader()}
        {this.renderChannels()}
      </div>
    );
  }

  renderLoading() {
    if (!this.context.ready) {
      return <Loader active />;
    }
  }

  renderHeader() {
    if (!this.context.ready) {
      return;
    }

    const { sort } = this.state;

    const count = this.context.getUnreadMessageCount();
    const s = count === 1 ? '' : 's';
    return (
      <div
        className={this.getElementClass('header', count > 0 ? 'unread' : null)}>
        <Layout center spread horizontal>
          <Layout.Group>
            {count} unread message{s}
          </Layout.Group>
          <Layout.Group>
            <Dropdown icon={<Icon name="custom filter" />} closeOnBlur={false}>
              <Dropdown.Menu>
                <div className={this.getElementClass('header-filters')}>
                  <Radio
                    label="Newest"
                    value="newest"
                    checked={sort === 'newest'}
                    onChange={this.onSortClick}
                  />
                  <Radio
                    label="Oldest"
                    value="oldest"
                    checked={sort === 'oldest'}
                    onChange={this.onSortClick}
                  />
                  <Radio
                    label="High Priority"
                    value="priority"
                    checked={sort === 'priority'}
                    onChange={this.onSortClick}
                  />
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Layout.Group>
        </Layout>
      </div>
    );
  }

  renderChannels() {
    const { channels } = this.context;

    if (!channels.length) {
      return <div>No active conversations.</div>;
    }

    const { sort } = this.state;
    const { currentChannel } = this.props;

    const sorted = [...channels];
    sorted.sort((a, b) => {
      return this.sortChannels(a, b, sort);
    });

    return (
      <div className={this.getElementClass('channels')}>
        {sorted.map((channel) => {
          const { url } = channel;

          const isSelected = channel.url === currentChannel?.url;
          const members = getPublicParticipants(channel);

          return (
            <div
              key={url}
              onClick={() => {
                this.props.onChannelSelect(channel);
              }}
              className={this.getElementClass(
                'item',
                isSelected ? 'selected' : null
              )}>
              {this.renderAvatars(members)}
              {this.renderNames(members)}
              {this.renderMeta(channel)}
            </div>
          );
        })}
      </div>
    );
  }

  renderAvatars(members) {
    return (
      <div className={this.getElementClass('avatars')}>
        {members.map((member) => {
          return (
            <React.Fragment key={member.userId}>
              <Avatar src={member.profileUrl} small />
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  renderNames(members) {
    const names = members
      .map((member) => {
        return member.nickname;
      })
      .join(', ');

    return <div className={this.getElementClass('names')}>{names}</div>;
  }

  renderMeta(channel) {
    return (
      <div className={this.getElementClass('meta')}>
        {this.renderUnread(channel)}
        {this.renderPriority(channel)}
      </div>
    );
  }

  renderUnread(channel) {
    const { user } = this.context;
    if (isManager(user)) {
      return;
    }
    const { unreadMessageCount } = channel;
    if (unreadMessageCount > 0) {
      return (
        <div className={this.getElementClass('unread-count')}>
          {unreadMessageCount}
        </div>
      );
    }
  }

  renderPriority(channel) {
    if (this.context.isPriorityChannel(channel)) {
      return <div className={this.getElementClass('priority')}>!</div>;
    }
  }
}
