import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic';

import { withSearchProvider } from '../Context';

@withSearchProvider
export default class SearchFilter extends React.Component {
  state = {
    value: this.props.context.filters[this.props.name],
  };

  componentDidUpdate(lastProps) {
    if (
      lastProps.context.filters[this.props.name] !==
      this.props.context.filters[this.props.name]
    ) {
      this.setState({
        value: this.props.context.filters[this.props.name],
      });
    }
  }

  onChange = (evt, { value }) => {
    const { isModalContext, onFilterChange } = this.props.context;
    if (isModalContext) {
      const { name } = this.props;
      onFilterChange({ value, name });
    } else {
      this.setState({
        value,
      });
    }
  };

  onKeyPress = (evt) => {
    const { isModalContext, onFilterChange } = this.props.context;
    if (evt.key === 'Enter' && !isModalContext) {
      const { value } = this.state;
      const { name } = this.props;
      onFilterChange({ value, name });
    }
  };

  render() {
    const { loading } = this.props.context;
    const { name, context, ...rest } = this.props;

    return (
      <Form.Input
        fluid
        loading={loading}
        disabled={loading}
        type="search"
        style={{ minWidth: '220px' }}
        placeholder="Search by keyword"
        icon={this.renderIcon()}
        value={this.state.value || ''}
        onChange={this.onChange}
        onKeyPress={this.onKeyPress}
        {...rest}
      />
    );
  }

  renderIcon() {
    const { name, context } = this.props;
    const value = this.state.value;
    return {
      name: value ? 'close' : 'magnifying-glass',
      link: true,
      onClick: (evt) => {
        if (value) {
          this.setState({
            value: '',
          });
          context.onFilterChange({ name, value: '' });
        }
        evt.target.closest('.input').querySelector('input').focus();
      },
    };
  }
}

SearchFilter.propTypes = {
  ...Form.Input.propTypes,
  name: PropTypes.string.isRequired,
};
