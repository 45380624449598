// react-hot-loader needs to be imported
// before react and react-dom
import 'react-hot-loader';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { Loader, Icon } from 'semantic';

import { SessionProvider, ThemeProvider, useSession } from 'stores';

import SessionSwitch from 'helpers/SessionSwitch';
import 'utils/sentry';

// Icons
import ScrollProvider from 'helpers/ScrollProvider';

import solidIcons from 'semantic/assets/icons/solid.svg';
import brandIcons from 'semantic/assets/icons/brands.svg';
import regularIcons from 'semantic/assets/icons/regular.svg';
import customIcons from 'semantic/assets/icons/custom.svg';

Icon.useSet(solidIcons);
Icon.useSet(brandIcons, 'brands');
Icon.useSet(regularIcons, 'regular');
Icon.useSet(customIcons, 'custom');

// Provider

import MeetingRoom from 'components/MeetingRoom';
import SendbirdChat from 'components/SendbirdChat';

import { isManager, isProvider } from 'utils/user';

// Atomic styles

import './styles/atomic.less';

// Set global time zone for users who don't have it set.
import { DateTime } from 'utils/date';
DateTime.setTimeZone('America/New_York');

const AuthApp = React.lazy(() => import('./auth/App'));
const DocsApp = React.lazy(() => import('./docs/App'));
const ManagerApp = React.lazy(() => import('./manager/App'));
const ProviderApp = React.lazy(() => import('./provider/App'));

function AppSwitch() {
  const { user } = useSession();
  if (isManager(user)) {
    return <ManagerApp />;
  } else if (isProvider(user)) {
    return <ProviderApp />;
  } else {
    return <AuthApp />;
  }
}

const Wrapper = () => (
  <BrowserRouter>
    <ThemeProvider>
      <HelmetProvider>
        <ScrollProvider>
          <SessionProvider>
            <MeetingRoom.Provider>
              <SessionSwitch>
                <Suspense fallback={<Loader active />}>
                  <SendbirdChat.Provider>
                    <Switch>
                      <Route path="/docs" component={DocsApp} />
                      <Route path="/" component={AppSwitch} />
                    </Switch>
                  </SendbirdChat.Provider>
                </Suspense>
              </SessionSwitch>
            </MeetingRoom.Provider>
          </SessionProvider>
        </ScrollProvider>
      </HelmetProvider>
    </ThemeProvider>
  </BrowserRouter>
);

ReactDOM.render(<Wrapper />, document.getElementById('root'));
