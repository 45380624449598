import React from 'react';
import { Loader } from 'semantic';

import { request } from 'utils/api';

import client from './client';
import Channel from './Channel';
import Context from './Context';

export default class SendbirdSingle extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      channel: null,
    };
  }

  componentDidMount() {
    this.setup();
  }

  async setup() {
    this.setState({
      channel: await this.getChannel(),
    });
  }

  async getChannel() {
    const { user } = this.props;
    if (user) {
      return await this.connectUser(user);
    } else {
      return await this.connectMine();
    }
  }

  async connectUser(user) {
    const { data } = await request({
      method: 'POST',
      path: '/1/chat/connect',
      body: {
        user: user.id,
      },
    });
    return await client.groupChannel.getChannel(data.channel_url);
  }

  async connectMine() {
    const { data } = await request({
      method: 'GET',
      path: '/1/chat/mine',
    });
    return await client.groupChannel.getChannel(data.channel_url);
  }

  render() {
    const { ready } = this.context;
    const { channel } = this.state;
    if (!ready || !channel) {
      return <Loader active />;
    }
    return <Channel channel={channel} single />;
  }
}
